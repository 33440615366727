import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import CustomIcon from "src/ui/components/CustomIcon/CustomIcon";
import SanityBlockContent, {
  type SanityContentBlock
} from "src/ui/components/SanityBlockContent/SanityBlockContent";

const List = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: 0.3fr 1fr;
  max-width: 300px;
  margin: 20px auto;

  p {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.5;
  }
  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    margin: 40px auto;
  }
`;

interface Box {
  highlightIcon: boolean;
  highlightTitle: boolean;
  icon: string;
  text: Record<string, unknown>;
  title: string;
}

interface Props {
  boxes: Box[];
}

const InfoBox: FC<Props> = ({ boxes }) => {
  return (
    <List>
      {boxes.map((box) => (
        <>
          <CustomIcon icon={box.icon} />
          <SanityBlockContent blocks={box.text as SanityContentBlock} />
        </>
      ))}
    </List>
  );
};

export default InfoBox;
