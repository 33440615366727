import type { ReactElement } from "react";
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import translate from "src/lib/translate";

type ImageImportType = Promise<{ readonly default: string }>;

const drop = async (): ImageImportType => import("../../assets/icons/drop.png");
const bottle = async (): ImageImportType =>
  import("../../assets/icons/bottle.png");
const cupcake = async (): ImageImportType =>
  import("../../assets/icons/cupcake.png");
const sleep = async (): ImageImportType =>
  import("../../assets/icons/sleep.png");
const eye = async (): ImageImportType => import("../../assets/icons/eye.png");
const scale = async (): ImageImportType =>
  import("../../assets/icons/scale.png");
const foot = async (): ImageImportType => import("../../assets/icons/foot.png");
const medication = async (): ImageImportType =>
  import("../../assets/icons/medication.png");
const check = async (): ImageImportType =>
  import("../../assets/icons/check.png");
const personalCare = async (): ImageImportType =>
  import("../../assets/icons/personal-care.png");

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScaledImage = styled.img`
  width: 62%;
  height: auto;
  display: block;
`;

const AsyncIcon = ({
  src,
  alt,
  width,
  height
}: {
  alt: string;
  src: () => ImageImportType;
  width: string;
  height: string;
}): ReactElement | null => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    void src().then((result) => {
      setImageSrc(result.default);
    });
  }, [src]);

  return !imageSrc ? null : (
    <ScaledImage src={imageSrc} alt={alt} width={width} height={height} />
  );
};

const icons: Record<string, () => ReactElement> = {
  drop: () => (
    <AsyncIcon
      alt={translate("icon.alt.waterDrop")}
      src={drop}
      width="180"
      height="180"
    />
  ),
  bottle: () => (
    <AsyncIcon
      alt={translate("icon.alt.waterBottle")}
      src={bottle}
      width="180"
      height="184"
    />
  ),
  cupcake: () => (
    <AsyncIcon
      alt={translate("icon.alt.cupcake")}
      src={cupcake}
      width="180"
      height="180"
    />
  ),
  sleep: () => (
    <AsyncIcon
      alt={translate("icon.alt.personSleeping")}
      src={sleep}
      width="180"
      height="184"
    />
  ),
  eye: () => (
    <AsyncIcon
      alt={translate("icon.alt.eye")}
      src={eye}
      width="180"
      height="180"
    />
  ),
  scale: () => (
    <AsyncIcon
      alt={translate("icon.alt.weightScale")}
      src={scale}
      width="180"
      height="180"
    />
  ),
  foot: () => (
    <AsyncIcon
      alt={translate("icon.alt.foot")}
      src={foot}
      width="180"
      height="184"
    />
  ),
  medication: () => (
    <AsyncIcon
      alt={translate("icon.alt.medicationBottle")}
      src={medication}
      width="180"
      height="184"
    />
  ),
  check: () => (
    <AsyncIcon
      alt={translate("icon.alt.checkMark")}
      src={check}
      width="180"
      height="184"
    />
  ),
  personalCare: () => (
    <AsyncIcon
      alt={translate("icon.alt.person")}
      src={personalCare}
      width="180"
      height="184"
    />
  )
};

const CustomIcon = ({ icon }: { icon: string }): ReactElement => {
  const ref = useRef(null);
  const Icon = icons[icon];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const content = Icon ? <Icon /> : <span>{icon}</span>;
  return <Wrap ref={ref}>{content}</Wrap>;
};

export default CustomIcon;
